/* ======================  SCRIPTS ====================== */
/****** breakpoints ******/
var breakpoint_xs = 0;
var breakpoint_sm = 576;
var breakpoint_md = 760;
var breakpoint_lg = 1000;
var breakpoint_xl = 1200;

/****** var. globales ******/

window.tel = false;
window.utm = false;

/****** init. doc ******/
$('document').ready(setup);

shopList();

/****** setup ******/
function setup() {
    // initTarteAuCitron();

    /** init block-video **/
    $('body').on('click touch', '.block-video__link', function(e) {
        e.preventDefault();

        $(this).parent().find('.block-video__content')
            .append('<iframe class="block-video__iframe" src="' + $(this).attr('href') + '" frameborder="0" allowfullscreen="1" allow="autoplay; encrypted-media"></iframe>')
            .addClass('show');
    });

    /** init block-quotation **/
    if (!isMobile()) {
        refreshFormQuotationPosition();
        //blockQuotationTopBottom = blockQuotation.outerHeight() + blockQuotation.position().top;
    }

    /** GLOBAL : resize **/
    $(window).resize(function(e) {
        if (!isMobile()) {
            refreshFormQuotationPosition();
            //refreshFormQuotationPositionFixed(true);
        }
    });

    /** GLOBAL : scroll **/
    $(window).on('scroll', function(){
        if (!isMobile()) {
            refreshFormQuotationPosition();
            //refreshFormQuotationPositionFixed(false);
        }
    });

    // $('.submit-form').click(verifForm);
}
var onloadCallback = function () {
    grecaptcha.render('submit', {
        'sitekey' : '6Le4S7AUAAAAABNDsf-andmXwuOb6Fhuxk0EV8j9\n',
        'callback' : verifForm
    });
};

/****** initTarteAuCitron ******/
function initTarteAuCitron() {
    tarteaucitron.init({
        "hashtag": "#tarteaucitron", /* Ouverture automatique du panel avec le hashtag */
        "highPrivacy": false, /* désactiver le consentement implicite (en naviguant) ? */
        "orientation": "bottom", /* le bandeau doit être en haut (top) ou en bas (bottom) ? */
        "adblocker": false, /* Afficher un message si un adblocker est détecté */
        "showAlertSmall": false, /* afficher le petit bandeau en bas à droite ? */
        "cookieslist": true, /* Afficher la liste des cookies installés ? */
        "removeCredit": true, /* supprimer le lien vers la source ? */
        "useExternalCss": true
    });

    (tarteaucitron.job = tarteaucitron.job || []).push('youtube');
}

/****** isMobile ******/
function isMobile() {
    return ($(window).outerWidth() < breakpoint_lg);
}


/****** refreshFormQuotationPosition ******/
function refreshFormQuotationPosition() {
    var blockQuotation = $('.block-quotation');
    var blockQuotationHeight =  blockQuotation.outerHeight();
    var blockQuotationTop = 0;

    if (blockQuotation.hasClass('fixed')) {
        blockQuotationTop = parseInt($('.block-quotation').css('top').replace('px', ''));
    }

    // si fenetre assez haute : position fixed
    if ($(window).innerHeight() > (blockQuotationHeight + blockQuotationTop)) {
        if (!blockQuotation.hasClass('fixed')) {
            blockQuotation.addClass('fixed');
        }

        // si block-quotation trop bas : supp position fixed
        if ($(window).scrollTop() >= $('body').outerHeight() - blockQuotation.outerHeight() - 40 - $('.footer').outerHeight() - 57) {
            blockQuotation.css('top', $('body').outerHeight() - blockQuotation.outerHeight() - $('.footer').outerHeight() - 57);
            blockQuotation.css('margin-top', '0');
            blockQuotation.removeClass('fixed');
        }
        else {
            blockQuotation.css('top', '');
            blockQuotation.css('margin-top', '');
        }
    }
    else {
        if (blockQuotation.hasClass('fixed')) {
            blockQuotation.removeClass('fixed');
        }
    }
}

function verifForm(e) {
    return new Promise(function (resolve, reject) {
        // if (grecaptcha === undefined) {
        //     //return;
        //     reject();
        // }
        //
        // var response = grecaptcha.getResponse();
        //
        // if (!response) {
        //     //return;
        //     reject();
        // }

        var errors = 0;
        $('.form .error').remove();
        $('.form').find('.js-required').each(function () {
            if ($(this).val().length === 0) {
                errors++;
                $(this).parent().append('<p class="error">Veuillez compléter le champs</p>')
            }else if ($(this).attr('name') === 'accept-terms') {
                if (!$(this).prop('checked')) {
                    errors++;
                    $(this).parent().append('<p class="error">Veuillez cocher la case</p>')
                }
            }
        })
            .promise()
            .done(function () {
                if (errors == 0){
                    postLeads()
                }else {
                    // grecaptcha.reset();
                }
            });
    })
}


$( "#form" ).submit(function( event ) {
    event.preventDefault();
    postLeads();
});

function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function postLeads() {
    var data = $('.form').serializeArray();

    var hashFirst = md5.create();
    hashFirst.update($('#nom').val());
    hashFirst.hex();
    var hashLast = md5.create();
    hashLast.update($('#prenom').val());
    hashLast.hex();
    data.push({'name':'verif', 'value': hashFirst + '/' +  hashLast});
    data.push({'name':'mail_agence', 'value': window.mail});

    $.ajax({
        url: 'https://bo-veka.prod.hebergement-gm.fr/api/postLeads',
        type: 'POST',
        data: data,
        dataType: 'json',
        success: function (res, statut) {
            /*gtag('event', 'submit_free_sample', {
                'event_category': 'form',
                'event_label': 'free-sample',
                'event_value': $('#nom').val()
            });*/
            window.location.href = 'confirmation.html';
        },
        error: function (res, statut, err) {
            $('.form').prepend('<p class="error">Error connexion to API.</p>')
        },
        complete: function (res, statut) {
            var result = JSON.parse(res.responseText);
            if (result.success) {

            }else {
                $('.form').prepend('<p class="error">Erreur</p>')
            }
        }
    });
}
function shopList() {
    var source = getParameterByName('utm_campaign');
    var data = {};
    data['key'] = source;
    $.ajax({
        url: 'https://bo-veka.prod.hebergement-gm.fr/api/shopInfos',
        type: 'POST',
        data: data,
        dataType: 'json',
        success: function (result) {
            $('.nom').text(result['data']['nom']);
            $('.adresse').text(result['data']['adresse']);
            $('.ville').text(result['data']['ville']);
            $('.mail').text(result['data']['mail']);
            $('.horaires').text(result['data']['horaires']);
            $('.horaires').html($('.horaires').text().replace(";", '<br/>'));
            window.tel = result['data']['tel'];
            window.utm = result['data']['utm'];
            window.mail = result['data']['mail'];
            createMap();
        },
        error: function (res, statut, err) {
            $('.form').prepend('<p class="error">Error connexion to API.</p>')
        },
        complete: function (res, statut) {
            var result = JSON.parse(res.responseText);
            if (result.success) {

            }else {
                $('.form').prepend('<p class="error">Erreur</p>')
            }
        }
    });
}
$('.tel').on('click',function () {
    if (window.tel){
        $('.tel').text(window.tel);
    }
});

function createMap() {
    var locations = [
        ["<b>ARCADE</b><br>", 49.48641, 0.10717, "ARCADE"],
        ["<b>ALUFERM</b><br>", 46.9919099, 5.65504, "ALUFERM"],
        ["<b>ASD OUEST 79</b><br>", 46.32811, -0.44641, "ASD_OUEST_2"],
        ["<b>ASD OUEST 85</b><br>", 46.4217, -0.70972, "ASD_OUEST_1"],
        ["<b>ASK - VSE</b><br>", 43.78004, 4.29172, "ASK_VSE"],
        ["<b>ATOUT FERMETURE</b><br>", 47.29883, 5.10387, "ATOUT_FERMETURE"],
        ["<b>AZUR BAIE</b><br>", 43.29104, 5.41829, "AZUR_BAIE"],
        ["<b>BALLAT SA</b><br>", 44.27757, 2.44514, "BALLAT_SA"],
        ["<b>COTE OUVERTURE</b><br>", 46.06002, 4.04717, "COTE_OUVERTURE"],
        ["<b>FB MENUISERIES</b><br>", 47.73363, 3.29323, "FB_MENUISERIES"],
        ["<b>FDL</b><br>", 43.19402, 3.01, "FDL"],
        ["<b>FINISTERA</b><br>", 43.61765, 1.43657, "FINESTRA_1"],
        ["<b>FINISTERA</b><br>", 43.69341, 1.43891, "FINESTRA_2"],
        ["<b>GENDRON ET BESNARD SARL</b><br>", 48.65911, -1.96898, "GENDRON_ET_BERNARD_SARL"],
        ["<b>LA FENÊTRE NICOISE</b><br>", 43.71047, 7.29296, "LA_FENETRE_NICOISE"],
        ["<b>LM FERMETURES</b><br>", 48.9916, 2.09049, "LM_FERMETURES_1"],
        ["<b>LM FERMETURES</b><br>", 48.94096, 2.1659, "LM_FERMETURES_2"],
        ["<b>M.E.I.D</b><br>", 48.88063, 2.32148, "MEID"],
        ["<b>MAPES SERVICES</b><br>", 48.85853, 2.59238, "MAPES_SERVICES"],
        ["<b>MENUILAND</b><br>", 49.17494, -0.34204, "MENUILAND"],
        ["<b>Menuiserie Laurent CARRION</b><br>", 43.31419, 3.37754, "MENUISERIE_LAURENT_CARRION"],
        ["<b>MVM</b><br>", 43.51746, 4.9608, "MVM"],
        ["<b>Nouvelles Fenêtres</b><br>", 48.91655, 2.27753, "NOUVELLES_FENETRES_1"],
        ["<b>Nouvelles Fenêtres</b><br>", 48.81477, 2.19354, "NOUVELLES_FENETRES_2"],
        ["<b>POLYPOSE</b><br>", 45.68355, 0.17502, "POLYPOSE_1"],
        ["<b>POLYPOSE</b><br>", 45.73476, -0.61023, "POLYPOSE_2"],
        ["<b>PVC SYSTEM</b><br>", 43.67387, 1.36032, "PVC_SYSTEM"],
        ["<b>R.C.M La ville du Bois</b><br>", 48.6577, 2.27479, "R.C.M_ville_Bois"],
        ["<b>R.C.M Etampes</b><br>", 48.43346, 2.16626, "R.C.M_Etampes"],
        ["<b>R.C.M Saint Geneviève</b><br>", 48.63336, 2.335, "R.C.M_Saint_Genevieve"],
        ["<b>R.C.M Orsay</b><br>", 48.69745, 2.18329, "R.C.M_Orsay"],
        ["<b>R.C.M Montgeron</b><br>", 48.70696, 2.45357, "R.C.M_Montgeron"],
        ["<b>R.C.M Pithiviers</b><br>", 48.17126, 2.25502, "R.C.M_Pithiviers"],
        ["<b>TMA CARTIER</b><br>", 47.46485, -0.62251, "TMA_CARTIER"],
        ["<b>V.M.A Fenétrier</b><br>", 45.90576, 6.1193, "VMA_FENETRIER_1"],
        ["<b>V.M.A Fenétrier</b><br>", 46.2029567, 6.2458553, "VMA_FENETRIER_2"],
        ["<b>V.M.A Fenétrier</b><br>", 46.3719798, 6.4980978, "VMA_FENETRIER_3"],
        ["<b>ISOPLAT</b><br>", 43.2060993,2.3158988, "ISOPLAT"],
        ["<b>La Fenêtre Cannoise</b><br>", 43.5626065, 7.0143176, "FENETRE_CANNOISE"],
        ["<b>R.C.M Antony</b><br>", 48.7436833, 2.3006362, "RCM_Antony"]
    ];
    var mymap = L.map('mapid').setView([46.3630104, 2.9846608], 6);
    L.tileLayer.provider('CartoDB.Positron').addTo(mymap);
    for (var i = 0; i < locations.length; i++) {
        marker = new L.marker([locations[i][1], locations[i][2]])
            .bindPopup(locations[i][0])
            .addTo(mymap);
        if (locations[i][3] === window.utm) {
            mymap.flyTo([locations[i][1], locations[i][2]],14);
        }
    }
}